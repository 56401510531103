import './Loading.css'

function Loading() {
    return (
        <div className='loading-container'>
            <p>loading ...</p>
        </div>
    )
}

export default Loading